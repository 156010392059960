<template>
  <div>
    <div class="header">
      <div class="buff" @click="$router.push('/')">
        <fa-icon icon="arrow-left" class="my-icon"/> 
      </div>
      <div>
        JualanSayur.com
      </div>
      <div class="info-toko" @click="gotoAbout()">i</div>
    </div>
    <div class="wrapper-main">
        <div class="title-detail-belanja">Detail Belanja</div>
        <div class="list-biaya">
          <!-- <div class="list-detail" v-for="(item, index) in cart" :key="'itm-' + index">
              {{ item.name }} : {{ item.quantity }} * {{ item.price }}
          </div> -->
          <b-table striped hover :items="cart" :fields="fields">

            <!-- A custom formatted column -->
            <template v-slot:cell(price)="data">
              Rp.{{ data.item.price }},-
            </template>

            <!-- A custom formatted column -->
            <template v-slot:cell(subtotal)="data">
              Rp.{{ data.item.price * data.item.quantity }},-
            </template>
          </b-table>
        </div>
        <div class="price-c">Total Harga Rp.{{ total }},-</div>
        <!-- <b-button block variant="success" @click="$router.push('/')"> <fa-icon icon="phone" class="my-icon" 
        style="font-family: 'Open Sans';
        font-size: 15px;
        font-weight: 800;"/> Pesan Via Whatsapp</b-button> -->
        <!-- <p>Total : {{ total }}</p> -->
        <br/>
        <div class="form-pembeli">
            <div class="title-pembeli">Data Pembeli</div>
            <b-form-group
            id="fieldset-1"
            description=""
            label="Nama Lengkap"
            label-for="input-1"
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="state"
            style="text-align: left; font-size: 10pt"
          >
            <b-form-input id="input-1" v-model="name" :state="state" trim></b-form-input>
          </b-form-group>

          <b-form-group
            id="fieldset-1"
            description=""
            label="Alamat"
            label-for="input-1"
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="state"
            style="text-align: left; font-size: 10pt"
          >
            <b-form-input id="input-1" v-model="name" :state="state" trim></b-form-input>
          </b-form-group>

          <b-form-group
            id="fieldset-1"
            description="*wajib"
            label="Isi Nomor Whatsapp"
            label-for="input-1"
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="state"
            style="text-align: left; font-size: 10pt"
          >
            <b-form-input id="input-1" v-model="name" :state="state" trim></b-form-input>
          </b-form-group>
        </div>

        <div class="footer">
        <div class="btn-cart" @click="pesanWA()"><fa-icon icon="phone" class="my-icon"/>    Pesan Via Whatsap</div>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import { mapGetters } from 'vuex'

export default {
    name: 'home',
    components: {
        // HelloWorld
    },
    data() {
      return {
        name: '',
        fields: [
          {
            key: 'name',
            label: 'Produk',
            // sortable: true
          },
          {
            key: 'price',
            label: 'Harga',
            // sortable: false
          },
          {
            key: 'quantity',
            label: 'Qty',
            // sortable: true,
          },
          {
            key: 'subtotal',
            label: 'Sub Total',
            // sortable: true,
          }
        ],
      }
    },
    methods: { 

      pesanWA(){
        // window.open('http://menica.pro/' + this.currentURL, '_blank');
        window.open('https://api.whatsapp.com/' + 'send?phone=628567637243&text=Saya Ingin Order Barang dibawah ini : ');
        
      },

      gotoAbout(){
          this.$router.push({ name: 'About'})
      },

    },
    computed: {
        cart() { return this.$store.getters.cart },
        cartLength(){
            var count = 0

            this.$store.getters.cart.forEach(element => {
            count += element.quantity
            });

            return count
        },
        total(){
            var count = 0

            this.$store.getters.cart.forEach(element => {
            count += (element.quantity * element.price)
            });

            return count
        }
    }
}
</script>

<style>
.title-detail-belanja{
  text-align: left;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 13pt;
}
.title-pembeli{
  text-align: left;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 13pt;
  margin-bottom: 10px;
}
.list-biaya{
  padding: 10px;
  text-align: left;
}
.list-detail{
  font-family: 'Open Sans';
  font-size: 10pt;
}
.price-c{
    background: #f2f2f2;
    border: 1px solid #dee2e6;
    font-weight: 900;
    font-family: 'Open Sans';
    color: #6f6f6f;
    text-transform: uppercase;
    font-size: 12pt;
    padding: 10px;
    border-radius: 4px;
}
.form-pembeli{
  padding: 20px;
  border: 1px solid #dee2e6;
  background: #f2f2f2;
}
</style>
