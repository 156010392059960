<template>
  <div>
    <div class="header">
      <div class="buff" @click="$router.push('/')">
        <fa-icon icon="arrow-left" class="my-icon"/> 
      </div>
      <div>
        Detail Produk
      </div>
      <div class="info-toko" @click="gotoAbout()">i</div>
    </div>
    <div class="wrapper-main">
      <div class="img-slider">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <!-- Text slides with image -->
          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52"
          ></b-carousel-slide>

          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
           
          </b-carousel-slide>

          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=58">
          
          </b-carousel-slide>

        </b-carousel>
      </div>
      <div class="product">
        <div class="product-dt">
            <!-- {{$route.params.productId}} -->
            <div class="dt-title">
              {{productDetail[$route.params.productId].name}}
            </div>
            <div class="dt-desc">
              <!-- Rp. {{productDetail[$route.params.productId].price}},- -->
              <p>
                {{productDetail[$route.params.productId].description}}
              </p>
            </div>
            <div class="dt-title-lower">
              Harga : Rp.{{productDetail[$route.params.productId].price}},-
            </div>

             
        </div>
      </div>
    </div>
    <div class="footer">
      <div v-if="productDetail[$route.params.productId].count > 0" style="width: 100%">
        <div class="flex-space-between" style="margin: 10px; height: 50px; padding: 0px; width: 95%">
          <div class="btn-small center-xy"  @click="removeFromCart(productDetail[$route.params.productId])">-</div>
          <div class="state-count center-xy fontGrey" >{{productDetail[$route.params.productId].count}}</div>
          <div class="btn-small center-xy" @click="addToCart(productDetail[$route.params.productId])">+</div>
        </div>
      </div>
      <b-button class="btn-crt" style="width: 95%;"
      block variant="warning" @click="addToCart(productDetail[$route.params.productId])" v-else>Add to Cart</b-button>

      <div class="btn-cart" @click="$router.push('/ck')">Total IDR. {{ total }},-</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'home',
  components: {
    
  },
   data() {
      return {
        showPlusMin: false,
        slide: 0,
        sliding: null,
        productDetail: this.$store.state.products,
        produk: {}
      }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      },
      addToCart(item) {
        this.$store.dispatch('addToCart', item);
      },
      removeFromCart(item) {
        this.$store.dispatch('removeFromCart', item)
      },
      gotoAbout(){
          this.$router.push({ name: 'About'})
      },
    },
    computed: {
      cartLength(){
        var count = 0

        this.$store.getters.cart.forEach(element => {
          count += element.quantity
        });

        return count
      },
      total(){
        var count = 0

        this.$store.getters.cart.forEach(element => {
          count += (element.quantity * element.price)
        });

        return count
      },
      created(){
        // this.produk = this.productDetail[$route.params.productId];
        // console.log(this.productDetail[$route.params.productId])
        console.log('123')
      }
    }
}
</script>

<style>
.dt-title{
  font-family: 'Open Sans';
  text-transform: uppercase;
  font-size: 2.5vh;
  text-align: left;
  font-weight: 700;
  color: #828282;
  padding: 10px 10px 0px 10px;
}

.dt-title-lower{
  font-family: 'Open Sans';
  font-size: 2.3vh;
  text-align: left;
  font-weight: 700;
  color: #828282;
  padding: 10px 10px 0px 10px;
}

.dt-desc{
  text-align: left;
  margin-top: 5px;
  font-size: 1.8vh;
  padding: 0px 10px;
}

.btn-crt{
  margin-top: 10px;
  margin: 10px;
  margin: auto;
  font-family: 'Open Sans';
  font-weight: 900;
  color: #727272;
  border-radius: 10px;
  font-size: 1.7vh;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 10px;
  webkit-box-shadow: rgba(152, 152, 152, 0.2) 0px 2px 8px 1px;
  -webkit-box-shadow: rgba(152, 152, 152, 0.2) 0px 2px 8px 1px;
  box-shadow: rgba(152, 152, 152, 0.2) 0px 2px 8px 1px;
          
}

.center-xy{
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  font-size: 15pt;
}

.fontGrey{
  color: #7c7c7c;
  font-family: 'Open Sans';
}
</style>
