<template>
  <div>
    <div class="header">
      <div class="buff">
        
      </div>
      <div>
        JualanSayur.com
      </div>
      <div class="info-toko" @click="gotoAbout()">i</div>
    </div>
    <div class="wrapper-main">
      <div class="img-slider">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <!-- Text slides with image -->
          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52"
          ></b-carousel-slide>

          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
           
          </b-carousel-slide>

          <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=58">
          
          </b-carousel-slide>

        </b-carousel>
      </div>
      <div class="cat-filter">
        <h4>Kategori</h4>
        <div style="position: relative; width: auto; overflow-x: scroll;backgrund: red">
        <b-tabs class="my-tab" style="position: abslute; min-width: 700px" v-model="tabID">
          <b-tab active>
            <template v-slot:title>
               Semua 
            </template>
          </b-tab>

          <b-tab>
            <template v-slot:title>
               Promo
            </template>
          </b-tab>

          <b-tab>
            <template v-slot:title>
               Minuman
            </template>
          </b-tab>

          <b-tab>
            <template v-slot:title>
               Snack
            </template>
          </b-tab>

          <b-tab>
            <template v-slot:title>
               Makanan Cepat Saji
            </template>
          </b-tab>
          
          <b-tab>
            <template v-slot:title>
               Free
            </template>
          </b-tab>

        </b-tabs>
        </div>
      </div>
      <div class="search">
        <b-input-group size="lg">
          <template v-slot:append>
            <div class="append-search">
              <fa-icon icon="search" class="my-icon" />
            </div>
          </template>
          <b-form-input style="height: 35px; border-right: none;" v-model="search"></b-form-input>
        </b-input-group>
      </div>
      <div class="product">
        <div class="product-list">
          <div v-for="(i, index) in filteredList" :key="index" class="produk-wrapper" >
            <div @click="gotoDetail(index)">
              <img :src="i.image" width="100%"/>
            </div>
            <div class="title-product" @click="gotoDetail(index)">
              {{i.name}}
            </div>
            <div class="product-price">
              Rp. {{i.price}},-
            </div>
            <div style="border-bottom: 1px solid whitesmoke;margin: 15px;"></div>
            <div>
              <div v-if="i.count > 0">
                <div class="flex-space-between" style="margin: 10px; max-height: 40px; padding: 0px 10px">
                  <div class="btn-small" @click="removeFromCart(i)">-</div>
                  <div class="state-count">{{i.count}}</div>
                  <div class="btn-small" @click="addToCart(i)">+</div>
                </div>
              </div>
              <b-button style="
                margin-top: 10px;
                margin: 10px;
                width: 80%;
                margin: auto;
                font-family: 'Open Sans';
                font-weight: 900;
                color: #727272;
                border-radius: 10px;
                font-size: 1.7vh;
              "
              block variant="warning" @click="addToCart(i)" v-else>Add to Cart</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <!-- <b-button block variant="warning" v-if="false">Kontak Pedagang</b-button> -->
      <div class="btn-cart" v-if="total == 0" @click="pesanWA()"><fa-icon icon="phone" class="my-icon"/>    Kontak Pedagang via Whatsapp</div>
      
      <div class="btn-cart flex-space-between aalign-center" style="padding-left: 4%; padding-right: 4%" @click="$router.push('/ck')" v-else>
        <div>Total IDR. {{ total }},- </div>
        <fa-icon icon="arrow-right" class="my-icon"/> 
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    // HelloWorld
  },
   data() {
      return {
        search: '',
        tabID: '',
        showPlusMin: false,
        slide: 0,
        sliding: null,
        productList: this.$store.state.products
      }
    },
    methods: {
      pesanWA(){
        // window.open('http://menica.pro/' + this.currentURL, '_blank');
        window.open('https://api.whatsapp.com/' + 'send?phone=628567637243&text=Halo saya mau tanya - tanya nih ');
        
      },
      tabSelected(){
        console.log(this.tabSS)
      },
      gotoDetail(i){
          this.$router.push({ name: 'Detail', params: { productId: i } })
      },
      gotoAbout(){
          this.$router.push({ name: 'About'})
      },
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      },
      addToCart(item) {
        this.$store.dispatch('addToCart', item);
      },
      removeFromCart(item) {
        this.$store.dispatch('removeFromCart', item)
      },
    },
    computed: {
      filteredList() {
        return this.productList.filter(post => {
          return post.name.toLowerCase().includes(this.search.toLowerCase())
        })
      },
      cartLength(){
        var count = 0

        this.$store.getters.cart.forEach(element => {
          count += element.quantity
        });

        return count
      },
      total(){
        var count = 0

        this.$store.getters.cart.forEach(element => {
          count += (element.quantity * element.price)
        });

        return count
      }
    }
}
</script>

<style>
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
.flex-space-between{
  display: flex;
  justify-content: space-between;
}
.aalign-center{
  align-items: center;
}
.count_{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  padding: 20px;
}
.buff{
  background: transparent;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding-left: 2%;
  cursor: pointer;
}
.info-toko{
  background: white;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  color: rgb(0, 174, 239);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 2%;  
  cursor: pointer;
}
.header{
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
    background: rgb(0, 174, 239);
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: white;
    font-weight: bolder;
}
.wrapper-main{
    background-color: #fff;
    position: relative;
    top: 60px;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    min-height: calc(100vh - 60px);
    margin: 0px auto;
    padding: 20px 16px 80px;
    height: auto;
    padding-bottom: 100px
}

.footer{
    list-style-type: none;
    display: inline-block;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    max-width: 480px;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
    font-size: 1em;
    background-color: rgb(255, 255, 255);
    border-width: 1px 0px 0px;
    border-image: initial;
    transition: all 0.35s ease 0s;
    text-decoration: none;
    padding: 10px;
    margin: 0px;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px 10px;
    color: white;
    flex-direction: column;
    /* background: rgb(0, 174, 239); */
    background: transparent;
}

.btn-cart{
  background: rgb(0, 174, 239);
  webkit-box-shadow: rgba(152, 152, 152, 0.2) 0px 2px 8px 1px;
  box-shadow: rgba(152, 152, 152, 0.2) 0px 2px 8px 1px;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  width: 95%;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;
}
.btn-cart:hover{
  background: tomato;
}

.cat-filter h4{
  text-align: left;
  font-size: 12pt;
  font-weight: bolder;
}


.cat-filter, .product, .search{
  margin: 20px 0px;
}

.input-group-append{
  height: 35px;
}
.input-group-text{
  font-size: 11px;
}
.my-tab .nav-tabs {
  padding-left: 29px;
}

.produk-wrapper{
  /* padding: 7px; */
  /* min-height: 286px; */
  height: 100%;
  box-shadow: rgba(152, 152, 152, 0.2) 0px 2px 8px 1px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-image: initial;
  border-radius: 15px;
  margin: 10px 5px;
  width: 43%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding-bottom: 15px;
}

.produk-wrapper img{
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.title-product{
  padding: 10px 5px 0px 10px;
  font-family: 'Open Sans';
  text-transform: uppercase;
  /* font-size: 10pt; */
  font-size: 1.5vh;
  text-align: left;
  font-weight: 800;
  color: #828282;
}

.product-price{
  padding: 0px 0px 0px 10px;
  font-family: 'Open Sans';
  text-transform: uppercase;
  /* font-size: 11pt; */
  font-size: 1.8vh;
  text-align: left;
  font-weight: 800;
  color: #3bbaed;
}

.append-search{
  background: rgb(234, 236, 239);
  padding: 10px;
  color: #636363;
  font-size: 10px;
}

.product-list{
  display: flex; 
  justify-content: space-around; 
  width: 100%; 
  flex-wrap:  wrap
}

.btn-small{
  width: 30%;
  height: 35%;
  border-radius: 3px;
  background-color: #3bbaed;
  color: white;
  font-family: 'Open Sans';
  font-weight: 800;
  cursor: pointer;
}

.state-count{
  border-radius: 3px;
  border: solid 0.5px #e6e6e6;
  background-color: #f7f7f7;
  width: 30%;
  height: 35%;
  margin: 0px 10px;
}

@media only screen and (max-width: 600px) {

}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 992px) {

}
</style>
