import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    products: [
      { id: 1, name: 'Madu Asli Mesir', description: 'Harga per 500gr Kentang punya banyak manfaat nih yang baik bukan hanya buat yang mau diet loh. Di dalam kentang ada Pati Resisten yang bisa bekerja baik untuk hormon pengendalian gula darah, jadi kalau mengkonsumsi kentang bagus buat penderita diabetes. Kentang juga Gluten Free loh, hanya jika diolah dengan benar yah. Kentang dikonsumsi anak sejak dini juga baik nih, soalnya bisa menjaga kesehatan jantung. Wah moms, jangan lupa beli kentang buat anak yah.', image: 'https://cdn.pixabay.com/photo/2014/07/31/23/00/wristwatch-407096__340.jpg', price: 340, count: 0 },
      { id: 2, name: 'Susu Kambing Original', description: 'Harga per 500gr Kentang punya banyak manfaat nih yang baik bukan hanya buat yang mau diet loh. Di dalam kentang ada Pati Resisten yang bisa bekerja baik untuk hormon pengendalian gula darah, jadi kalau mengkonsumsi kentang bagus buat penderita diabetes. Kentang juga Gluten Free loh, hanya jika diolah dengan benar yah. Kentang dikonsumsi anak sejak dini juga baik nih, soalnya bisa menjaga kesehatan jantung. Wah moms, jangan lupa beli kentang buat anak yah.', image: 'https://cdn.pixabay.com/photo/2016/12/10/16/57/shoes-1897708__340.jpg', price: 500, count: 0 },
      { id: 3, name: 'Jus Campur Tanpa Gula',  description: 'Harga per 500gr Kentang punya banyak manfaat nih yang baik bukan hanya buat yang mau diet loh. Di dalam kentang ada Pati Resisten yang bisa bekerja baik untuk hormon pengendalian gula darah, jadi kalau mengkonsumsi kentang bagus buat penderita diabetes. Kentang juga Gluten Free loh, hanya jika diolah dengan benar yah. Kentang dikonsumsi anak sejak dini juga baik nih, soalnya bisa menjaga kesehatan jantung. Wah moms, jangan lupa beli kentang buat anak yah.', image: 'https://cdn.pixabay.com/photo/2017/09/03/14/41/mock-up-2710535__340.jpg', price: 600, count: 0 },
      { id: 4, name: 'Safron Arab',  description: 'Harga per 500gr Kentang punya banyak manfaat nih yang baik bukan hanya buat yang mau diet loh. Di dalam kentang ada Pati Resisten yang bisa bekerja baik untuk hormon pengendalian gula darah, jadi kalau mengkonsumsi kentang bagus buat penderita diabetes. Kentang juga Gluten Free loh, hanya jika diolah dengan benar yah. Kentang dikonsumsi anak sejak dini juga baik nih, soalnya bisa menjaga kesehatan jantung. Wah moms, jangan lupa beli kentang buat anak yah.', image: 'https://cdn.pixabay.com/photo/2017/08/27/05/33/trousers-2685231__340.jpg', price: 600, count: 0 },
      { id: 5, name: 'Temulawak',  description: 'Harga per 500gr Kentang punya banyak manfaat nih yang baik bukan hanya buat yang mau diet loh. Di dalam kentang ada Pati Resisten yang bisa bekerja baik untuk hormon pengendalian gula darah, jadi kalau mengkonsumsi kentang bagus buat penderita diabetes. Kentang juga Gluten Free loh, hanya jika diolah dengan benar yah. Kentang dikonsumsi anak sejak dini juga baik nih, soalnya bisa menjaga kesehatan jantung. Wah moms, jangan lupa beli kentang buat anak yah.', image: 'https://cdn.pixabay.com/photo/2016/06/25/14/39/baseball-cap-1479012__340.png', price: 600, count: 0 },
      { id: 6, name: 'Madu untuk Brain',  description: 'Harga per 500gr Kentang punya banyak manfaat nih yang baik bukan hanya buat yang mau diet loh. Di dalam kentang ada Pati Resisten yang bisa bekerja baik untuk hormon pengendalian gula darah, jadi kalau mengkonsumsi kentang bagus buat penderita diabetes. Kentang juga Gluten Free loh, hanya jika diolah dengan benar yah. Kentang dikonsumsi anak sejak dini juga baik nih, soalnya bisa menjaga kesehatan jantung. Wah moms, jangan lupa beli kentang buat anak yah.', image: 'https://cdn.pixabay.com/photo/2016/04/16/17/46/glasses-1333433__340.jpg', price: 600, count: 0 },
    ],
    inCart: []
  },
  getters: {
      products: state => state.products,
      inCart: state => state.inCart,
      cart: state => {
        return state.inCart.map(({ id, quantity }) => {
          const product = state.products.find(p => p.id === id)
          return {
            name: product.name,
            price: product.price,
            quantity: quantity
          }
        })
      }
  },
  mutations: {
      ADD_TO_CART(state, item) {
        const index = state.inCart.findIndex(p => p.id === item.id)

        if(index >= 0) {
          state.inCart[index].quantity++
          item.count++
        } else {
          state.inCart.push({id: item.id, quantity: 1})
          item.count = 1
        }
      },
      REMOVE_FROM_CART(state, item) {
        const index = state.inCart.findIndex(p => p.id === item.id)

        if(index >= 0) {
          state.inCart[index].quantity--
          item.count--

          if(state.inCart[index].quantity === 0) {
            state.inCart.splice(index, 1);
          }
        }
      }
  },
  actions: {
      addToCart(context, item) {
          context.commit('ADD_TO_CART', item);
      },
      removeFromCart(context, item) {
          context.commit('REMOVE_FROM_CART', item);
      }
  }
})
