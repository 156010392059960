import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faHome,
  faCaretDown,
  faBars,
  faCamera,
  faStar,
  faAdjust,
  faHeart,
  faPencilAlt,
  faMapMarkerAlt,
  faMusic,
  faShareAlt,
  faEye,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faInfoCircle,
  faSearch,
  faUpload,
  faTimes,
  faDesktop,
  faMobileAlt,
  faExclamationTriangle,
  faBackward,
  faPhone,
  faArrowRight,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowLeft,
  faArrowRight,
  faPhone,
  faBackward,
  faHome,
  faCaretDown,
  faBars,
  faCamera,
  faStar,
  faAdjust,
  faHeart,
  faPencilAlt,
  faMapMarkerAlt,
  faMusic,
  faShareAlt,
  faEye,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faInfoCircle,
  faSearch,
  faUpload,
  faTimes,
  faDesktop,
  faMobileAlt,
  faExclamationTriangle
);

export default FontAwesomeIcon;
