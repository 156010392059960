<template>
  <div>
    <div class="header">
      <div class="buff" @click="$router.push('/')">
        <fa-icon icon="arrow-left" class="my-icon"/> 
      </div>
      <div>
        Profil Toko
      </div>
      <div class="info-toko" @click="gotoAbout()">i</div>
    </div>
    <div class="wrapper-main">
        <div class="title-detail-belanja">JualSayur.com</div>
        <div class="title-detail-belanja">Depok</div>
        <div class="title-detail-belanja">+62..</div>
        <!-- <div class="title-detail-belanja">JualSayur.com</div> -->
        <div class="list-biaya">
         
        </div>
        <!-- <div class="price-c">Total Harga Rp.{{ total }},-</div> -->
        
        

        <div class="footer">
        <div class="btn-cart" v-if="total == 0" @click="pesanWA()"><fa-icon icon="phone" class="my-icon"/>    Kontak Pedagang via Whatsapp</div>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import { mapGetters } from 'vuex'

export default {
    name: 'home',
    components: {
        // HelloWorld
    },
    data() {
      return {
        
        
      }
    },
    methods: { 

      pesanWA(){
        // window.open('http://menica.pro/' + this.currentURL, '_blank');
        window.open('https://api.whatsapp.com/' + 'send?phone=628567637243&text=Saya Ingin Order Barang dibawah ini : ');
        
      },

      gotoAbout(){
          this.$router.push({ name: 'About'})
      },

    },
    computed: {
        cart() { return this.$store.getters.cart },
        cartLength(){
            var count = 0

            this.$store.getters.cart.forEach(element => {
            count += element.quantity
            });

            return count
        },
        total(){
            var count = 0

            this.$store.getters.cart.forEach(element => {
            count += (element.quantity * element.price)
            });

            return count
        }
    }
}
</script>

<style>
.title-detail-belanja{
  text-align: left;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 13pt;
}
.title-pembeli{
  text-align: left;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 13pt;
  margin-bottom: 10px;
}
.list-biaya{
  padding: 10px;
  text-align: left;
}
.list-detail{
  font-family: 'Open Sans';
  font-size: 10pt;
}
.price-c{
    background: #f2f2f2;
    border: 1px solid #dee2e6;
    font-weight: 900;
    font-family: 'Open Sans';
    color: #6f6f6f;
    text-transform: uppercase;
    font-size: 12pt;
    padding: 10px;
    border-radius: 4px;
}
.form-pembeli{
  padding: 20px;
  border: 1px solid #dee2e6;
  background: #f2f2f2;
}
</style>
